import { Observable } from "rxjs";
import { RespInterface } from "@common/ports/interfaces/respt.interface";
import { RespListInterface, UserInterface } from "@common/ports/interfaces";
import { ProfileInterface } from "../interfaces";

export abstract class UserAbstraction {
  abstract list(query: string): Observable<RespListInterface<UserInterface>>;
  abstract create(user: UserInterface): Observable<RespInterface<UserInterface>>;
  abstract read(id: string): Observable<RespInterface<UserInterface>>;
  abstract profileGet(): Observable<RespInterface<UserInterface>>;
  abstract update(user: UserInterface): Observable<RespInterface<string>>;
  abstract profileUpdate(user: ProfileInterface): Observable<RespInterface<ProfileInterface>>;
  abstract delete(id: string): Observable<RespInterface<UserInterface>>;
}
